<template>
    <div>
        <Toast />
        <div class="card">
            <div class="p-grid p-fluid">
                <div class="p-col-12">
                    <h5>{{ $t('Managers.search.searchHeading') }}</h5>
                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-3">
                            <label for="type">{{ $t('Managers.search.Name') }}</label>
                            <InputText id="googlurl" type="text" :placeholder="$t('Managers.search.SBName')"
                                v-model="name" />
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('Managers.search.Email') }}</label>
                            <InputText id="googlurl" type="text" :placeholder="$t('Managers.search.SBEmail')"
                                v-model="email" />
                        </div>

                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('Managers.search.Phone') }}</label>
                            <InputText id="googlurl" type="text" :placeholder="$t('Managers.search.SBPhone')"
                                v-model="mobile" />
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('Managers.search.Country') }}</label>
                            <Dropdown v-model="country_id" :options="dropdownCountryValues" optionValue="id"
                                optionLabel="country_name" :placeholder="$t('Managers.search.Select')" />
                        </div>
                    </div>

                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('Managers.search.Status') }}</label>
                            <Dropdown v-model="status" :options="dropdownstatusValues" optionValue="code"
                                optionLabel="name" :placeholder="$t('Managers.search.Select')" />
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{ $t('Managers.search.CreationDate') }}</label>
                            <Calendar :showIcon="true" :showButtonBar="true" dateFormat="yy.mm.dd"
                                placeholder="YYYY/MM/DD - YYYY/MM/DD" v-model="dateRange" selectionMode="range"
                                :manualInput="false"></Calendar>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-d-flex p-jc-between p-ai-lg-center p-ai-start p-mt-6 p-flex-column p-flex-lg-row">
                <div class="p-mb-4 p-mb-lg-0"></div>
                <div>
                    <Button :label="$t('button.search')" icon="pi pi-search" iconPos="left"
                        class="p-button p-button-sm p-mr-2 p-mb-2" @click="searchManagermanagement()"></Button>
                    <Button :label="$t('button.reset')" icon="pi pi-replay" iconPos="left"
                        class="p-button p-button-sm p-mr-2 p-mb-2" @click="resetmanager()"></Button>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="p-d-flex p-jc-between p-mb-2">
                        <h4>{{ $t('Managers.list.Heading') }}</h4>
                        <div>
                            <router-link to="/ManagerManagementAdd">
                                <Button :label="$t('Managers.list.AddNew')" icon="pi pi-plus" iconPos="left"
                                    class="p-button p-button-sm p-mr-2 p-mb-2"></Button>
                            </router-link>
                        </div>
                    </div>
                    <DataTable :value="products" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
                        :rowHover="true" :loading="loading1" :filters="filters1" responsiveLayout="scroll"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        :rowsPerPageOptions="[10, 25, 50]"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                        <ConfirmDialog group="dialog" />

                        <template #empty>{{$t('Nodata')}}</template>
                        <template #loading>Loading data. wait.</template>

                        <Column field="#" header="#" style="min-width: 5rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Sl. No.</span>
                                {{ data.sl_no }}
                            </template>
                        </Column>
                        <Column field="companyname" :header="$t('Managers.list.Name')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Name</span>
                                {{ data.name }}
                            </template>
                        </Column>
                        <Column field="product" :header="$t('Managers.list.Email')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Email</span>
                                {{ data.email }}
                            </template>
                        </Column>
                        <Column field="service" :header="$t('Managers.list.Phone')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Mobile Number</span>
                                {{ data.country_code === null ? '' : "+" + data.country_code + " - " }} {{
                                    data.mobile
                                }}
                                <!-- {{ data.mobile == '' ? '-' : "+" + data.mobile }} -->
                            </template>
                        </Column>
                        <Column field="Status" :header="$t('Managers.list.Country')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Country</span>

                                {{ data.country_name }}
                            </template>
                        </Column>

                        <Column field="Creation-Date" :header="$t('Managers.list.Status')" style="min-width: 5rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Status</span>
                                {{ $t(data.status) }}
                            </template>
                        </Column>

                        <Column field="Creation-Date" :header="$t('Managers.list.CreationDate')"
                            style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Creation Date</span>

                                {{ startdateformat(data.createdDate) }}
                            </template>
                        </Column>

                        <Column field="Actions" :header="$t('Managers.list.Action')">
                            <template #body="{ data }">
                                <span class="p-column-title">Actions</span>
                                <p style="display: none">{{ data.status }}</p>
                                <div style="display: flex">
                                    <router-link :to="'/ManagerManagementView/' + data.id">
                                        <Button label="info" class="n-wrap p-button-outlined p-button-info p-mb-2">
                                            <i class="pi pi-eye p-mr-2"></i>
                                        </Button>
                                    </router-link>
                                    <router-link :to="'/ManagerManagementEdit/' + data.id">
                                        <Button label="help" class="n-wrap p-button-outlined p-button-help p-mb-2">
                                            <i class="pi pi-pencil p-mr-2"></i>
                                        </Button>
                                    </router-link>

                                    <Button icon="pi pi-trash" class="n-wrap p-button-danger p-button-outlined p-mb-2"
                                        @click="deleteNote(data.id)" />

                                    <!-- <Button :label="$t('button.delete')" icon="pi pi-trash" class="n-wrap p-button-danger p-button-outlined p-mr-2 p-mb-2" @click="confirm(data.id)" /> -->
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { useRoute } from 'vue-router';
import ManagerService from '../../../service/API/SuperAdmin/ManagerService.js';
import axios from 'axios';
import moment from 'moment';
export default {
    data() {
        return {
            // dropdownValues: [{ name: 'nft',code:'NFT' }, { name: 'card_news',code:'Card News' }, { name: 'media_press',code:'Media press' }, { name: 'de_fi_services',code:'De-Fi Services' }],
            dropdownstatusValues: [
                { name: '활성화', code: 'active' },
                { name: '비활성화', code: 'inactive' },
            ],
            serial: 0,
            dropdownstatusValue: null,

            isModalVisible: false,
            dropdownCountryValues: '',
            dropdownCountryValue: null,
            products: null,
            loading1: true,
            deletedID: null,
            sl_no: '',
            name: '',
            email: '',
            mobile: '',
            country_name: '',
            country_id: '',
            status: '',
            total: '',
            id: '',
            dateRange: '',
            searchdate: '',
            searchenddate: '',
            from_date: '',
            to_date: '',
        };
    },
    created() {
        this.managerService = new ManagerService();
    },
    mounted() {
        const route = useRoute();
        console.log(route.params);
        this.getManagers()
        // this.managerService
        //     .getManagerList(this.name, this.email, this.mobile, this.country_id, this.status, this.dateRange)
        //     .then((res) => {
        //         this.products = res.data.data.manager;
        //         this.total = res.data.data.length;
        //         this.loading1 = false;
        //         // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));

        //         console.log(res.data.data.manager);
        //     })

        //     .catch((err) => console.log(err));
        this.managerService
            .getCountrydropdown()
            .then((data) => {
                var newCountryList = data.data.data.country;
                for (let i = 0; i < newCountryList.length; i++) {
                    if (newCountryList[i].code == 'all') {
                        newCountryList.splice(i, 1);
                    }
                }
                this.dropdownCountryValues = newCountryList;
                // this.products = data;
                this.loading1 = false;
                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
                console.log(this.newCountryList);
            })
            .catch((err) => console.log(err));
    },
    methods: {

        getManagers() {
            const ManagerParams = {
                // page: 1,
                // limit: this.pagination.pagination_limit,
                name: this.name,
                country_id: this.country_id,
                email: this.email,
                from_date: this.searchdate,
                to_date: this.searchenddate,
                status: this.status,
                mobile: this.mobile,
            };
            this.loading1 = true;
            this.managerService
                .getManagerList(ManagerParams)
                .then((res) => {
                    this.products = res.data.data.manager;
                    this.totalrecords = res.data.total_records;
                    // this.setPagination(res.data.current_page, res.data.total_pages === 0 ? 1 : res.data.total_pages, res.data.total_records);
                    this.loading1 = false;
                    console.log(res);
                })
                .catch((err) => console.log(err));
        },
        addDay(val) {
            const date = new Date(val);
            var dd = date.getDate();
            var mm = date.getMonth() + 1;
            var yyyy = date.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            return (val = yyyy + '-' + mm + '-' + dd);
        },

        resetmanager() {
            this.name = '';
            this.email = '';
            this.mobile = '';
            this.country_id = '';
            this.status = '';
            this.dateRange = '';
            // this.calendarValue1 = '';
            this.loading1 = true;
            window.location.reload();
            // this.getManagers()

            // this.managerService
            //     .getManagerList(this.name, this.email, this.mobile, this.country_id, this.status, this.dateRange)
            //     .then((res) => {
            //         this.products = res.data.data.manager;
            //         this.total = res.data.data.total;
            //         this.loading1 = false;
            //         //console.log(data);
            //     })
            //     .catch(() => {
            //         this.products = [];
            //         this.loading1 = false;
            //     });
        },


        searchManagermanagement() {
            if (this.name === '' && this.email === '' && this.mobile === '' && this.country_id === '' && this.status === '' && this.dateRange === '') {
                this.$toast.add({ severity: 'error', summary: '에러 메시지', detail: '검색창을 입력해주세요.', life: 2000 });
            } else {
                if (this.dateRange.at(0) != '') {
                    this.searchdate = this.formatRangeDate(this.dateRange.at(0));
                } else {
                    this.searchdate = '';
                }
                if (this.dateRange.at(1) != '') {
                    this.searchenddate = this.formatRangeDate(this.dateRange.at(1));
                } else {
                    this.searchenddate = '';
                }
                const ManagerParams = {
                    // page: 1,
                    // limit: this.pagination.pagination_limit,
                    name: this.name,
                    country_id: this.country_id,
                    email: this.email,
                    from_date: this.searchdate,
                    to_date: this.searchenddate,
                    status: this.status,
                    mobile: this.mobile,
                };
                this.loading1 = true;
                this.managerService
                    .getManagerList(ManagerParams)
                    .then((res) => {
                        this.products = res.data.data.manager;
                        this.totalrecords = res.data.total_records;
                        // this.setPagination(res.data.current_page, res.data.total_pages === 0 ? 1 : res.data.total_pages, res.data.total_records);
                        this.loading1 = false;
                        console.log(res);
                    })
                    .catch((err) => console.log(err));
            }
        },

        dateformat(value) {
            if (value) {
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },

        startdateformat(value) {
            if (value) {
                return moment(String(value)).locale('ko').format('ll');
            }
        },
        formatRangeDate(date) {
            if (date === undefined || date === '') {
                return '';
            }
            return moment(new Date(date)).format('YYYY-MM-DD');
        },
        deleteNote: function (id) {
            console.log(id);
            var self = this;
            this.$confirm.require({
                group: 'dialog',
                header: '삭제',
                message: '정말로 삭제하시겠습니까?',
                icon: 'pi pi-trash',
                acceptLabel: "삭제",
                rejectLabel: "취소",
                accept: () => {
                    axios({ method: 'delete', url: '/asnhist-dev/api/v1/admin/manager/delete', data: { deleteIdArray: id } }).then(function (response) {
                        console.log(response);
                        self.managerService
                            .getManagerList(self.name, self.email, self.mobile, self.country_id, self.status)
                            .then((res) => {
                                self.products = res.data.data.manager;
                                //self.total = res.data.data.total;
                                self.loading1 = false;
                                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));

                                console.log(res);
                            })
                            .catch((err) => console.log(err));

                        // let i = this.products.map(data => data.id).indexOf(id);

                        // this.products.splice(i, 1)
                    });
                    this.$toast.add({ severity: 'info', summary: '삭제되었습니다.', detail: '성공적으로 삭제되었습니다.', life: 3000 });
                },
                // reject: () => {
                //     this.$toast.add({ severity: 'error', summary: 'An error occurred', detail: 'You have rejected', life: 3000 });
                // },

            });

        },

    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}

::v-deep(.p-datatable .p-sortable-column .p-column-title) {
    display: block;
}

.p-datatable .p-column-filter {
    display: none;
}

.table-header {
    display: flex;
    justify-content: space-between;
}

.customer-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-qualified {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-unqualified {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-negotiation {
        background: #feedaf;
        color: #8a5340;
    }

    &.status-new {
        background: #b3e5fc;
        color: #23547b;
    }

    &.status-renewal {
        background: #eccfff;
        color: #694382;
    }

    &.status-proposal {
        background: #ffd8b2;
        color: #805b36;
    }
}

.p-progressbar-value.ui-widget-header {
    background: #607d8b;
}

@media (max-width: 640px) {
    .p-progressbar {
        margin-top: 0.5rem;
    }
}

.product-image {
    width: 100px;
    height: 50px;
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.orders-subtable {
    padding: 1rem;
}

.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-outofstock {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-lowstock {
        background: #feedaf;
        color: #8a5340;
    }
}

.order-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.order-delivered {
        background: #c8e6c9;
        color: #256029;
    }

    &.order-cancelled {
        background: #ffcdd2;
        color: #c63737;
    }

    &.order-pending {
        background: #feedaf;
        color: #8a5340;
    }

    &.order-returned {
        background: #eccfff;
        color: #694382;
    }
}

.p-datatable {
    .p-sortable-column {
        &.p-highlight {
            &:hover {
                background: rgb(216 217 243);
                color: #464df2;
            }
        }
    }
}

.true-icon {
    color: #256029;
}

.false-icon {
    color: #c63737;
}

.w-100 {
    width: 100%;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
}

.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info>.p-button.p-button-outlined,
.p-splitbutton.p-button-info>.p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-info.p-button-outlined:hover,
.p-buttonset.p-button-info>.p-button.p-button-outlined,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:enabled:active {
    background: rgba(2, 136, 209, 0.16);
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help>.p-button.p-button-outlined,
.p-splitbutton.p-button-help>.p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-help.p-button-outlined:hover,
.p-buttonset.p-button-help>.p-button.p-button-outlined,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-danger.p-button-outlined:hover,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}
</style>